import {toQueryString} from "@/utils/queryString";

/**
 * The base API URL of the backend.
 */
export const apiUrl = `/api`;


/**
 * Construct commonly used headers.
 */
export function getDefaultHeaders(accessToken?: string) {
    return {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',

        ...(accessToken && {
            Authorization: `Bearer ${accessToken}`,
        }),
    };
}


function defaultFetchOptions(): RequestInit {
    return {
        method: 'GET',
        headers: getDefaultHeaders(),
        credentials: 'same-origin',
    };
}

/**
 * Parses the body of a Response as JSON.
 * @param res The Response.
 * @return A Promise resolving an object which contains the unmodified response and the parsed body (or null if it
 * couldn't be parsed).
 */
function handleBody(res: Response, {signal}: {
  signal?: AbortSignal
} = {}) {
    return new Promise<{
        res: Response;
        body: any
    }>((resolve, reject) => {
        if (signal) {
            if (signal.aborted) return reject();
            signal.addEventListener('abort', reject);
        }
        return res.json().then(
            body => {
                resolve({
                    res,
                    body,
                });
            },
            err => {
                resolve({
                    res,
                    body: null,
                });
            },
        );
    });
}


/**
 * Send a GET request to a endpoint.
 * @param url The path to the endpoint relative to the base URL of the backend. Without leading slash.
 * @return See handleBody()
 */
export async function get(
    url: string,
    {
        signal,
        payload,
    }: {
        signal?: AbortSignal;
        payload?: any
    } = {},
) {


    const res = await fetch(apiUrl + '/' + url + (payload ? '?' + toQueryString(payload) : ''), {
        ...defaultFetchOptions(),
        signal,
    });
    return await handleBody(res, {signal});
}

export async function restGet(url: string,  getAccessToken?: () => Promise<string>) {
    const accessToken = getAccessToken ? await getAccessToken() : null;
    return fetch(url, {
            headers: accessToken
                ? {"Authorization": `Bearer ${accessToken}`}
                : {}
    });
}

/**
 * Send a POST request to the logerror endpoint.
 * @param url The path to the endpoint relative to the base URL of the backend. Without leading slash.
 */
export async function postError(payload: any) {
    try {
        await fetch(apiUrl + '/explorer/logerror', {
            ...defaultFetchOptions(),
            method: 'POST',
            body: payload && JSON.stringify(payload),
        });
    } catch (e) {
        // Silently fail to log error.
    }
}
